import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Exotique/Header/Header';
import Footer from './Exotique/Footer/Footer';
import Home from './Exotique/Home/Home';
import Connection from './Exotique/Connection/Connection';
import MenuManagement from './Exotique/MenuManagement/MenuManagement';
import PrivateRoute from './Exotique/Services/PrivateRoute';
import { AuthProvider } from './Exotique/Services/AuthContext';
import NoMatch from './Exotique/Services/NoMatch';

const Layout = ({ children, onContactClick }) => {
    const location = useLocation();
    const currentPath = location.pathname.toLowerCase();

    const showHeaderFooter = currentPath !== '/connexion' && currentPath !== '/panel';

    return (
        <div>
            {showHeaderFooter && <Header onContactClick={onContactClick} />}
            {children}
            {showHeaderFooter && <Footer />}
        </div>
    );
};

const App = () => {
    const contactRef = useRef(null);

    const handleContactClick = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <AuthProvider>
            <Router>
                <Layout onContactClick={handleContactClick}>
                    <Routes>
                        <Route path="/" element={<Home contactRef={contactRef} />} />
                        <Route path="/connexion" element={<Connection />} />
                        <Route path="/panel" element={<PrivateRoute element={<MenuManagement />} />} />
                        <Route path="*" element={<NoMatch />} /> 
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
};

export default App;
