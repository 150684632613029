import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import './MenuManagement.css';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert'; 

function MenuManagement() {
    const { logout } = useAuth();
    const [entrances, setEntrances] = useState(['']);
    const [mains, setMains] = useState(['']);
    const [accompaniments, setAccompaniments] = useState(['']);
    const [price, setPrice] = useState('00');
    const [alert, setAlert] = useState({ message: '', type: '', isVisible: false }); 

    const entranceRefs = useRef([]);
    const mainRefs = useRef([]);
    const accompanimentRefs = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await fetch('https://alexotique.ch/alexotique/src/Exotique/Backend/Menu.php?id=1');
                const data = await response.json();

                if (data) {
                    setEntrances(data.entrance && data.entrance.length > 0 ? data.entrance : ['']);
                    setMains(data.main && data.main.length > 0 ? data.main : ['']);
                    setAccompaniments(data.accompaniment && data.accompaniment.length > 0 ? data.accompaniment : ['']);
                    setPrice(data.price || '');
                }
            } catch (error) {
                console.error('Erreur lors du chargement du menu:', error);
                showAlert('Erreur lors du chargement du menu.', 'error'); 
            }
        };

        fetchMenuData();
    }, []);

    useEffect(() => {
        entranceRefs.current = entranceRefs.current.slice(0, entrances.length);
    }, [entrances]);

    useEffect(() => {
        mainRefs.current = mainRefs.current.slice(0, mains.length);
    }, [mains]);

    useEffect(() => {
        accompanimentRefs.current = accompanimentRefs.current.slice(0, accompaniments.length);
    }, [accompaniments]);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleKeyDown = (event, index, type) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            switch (type) {
                case 'entrance':
                    setEntrances((prev) => {
                        const updated = [...prev, ''];
                        setTimeout(() => focusInput(updated.length - 1, type), 0);
                        return updated;
                    });
                    break;
                case 'main':
                    setMains((prev) => {
                        const updated = [...prev, ''];
                        setTimeout(() => focusInput(updated.length - 1, type), 0);
                        return updated;
                    });
                    break;
                case 'accompaniment':
                    setAccompaniments((prev) => {
                        const updated = [...prev, ''];
                        setTimeout(() => focusInput(updated.length - 1, type), 0);
                        return updated;
                    });
                    break;
                default:
                    break;
            }
        } else if ((event.key === 'Backspace' || event.key === 'Delete') && event.target.value === '') {
            event.preventDefault();
            switch (type) {
                case 'entrance':
                    if (entrances.length > 1) {
                        const newEntrances = entrances.filter((_, i) => i !== index);
                        setEntrances(newEntrances);
                        setTimeout(() => focusInput(index - 1, type), 0);
                    }
                    break;
                case 'main':
                    if (mains.length > 1) {
                        const newMains = mains.filter((_, i) => i !== index);
                        setMains(newMains);
                        setTimeout(() => focusInput(index - 1, type), 0);
                    }
                    break;
                case 'accompaniment':
                    if (accompaniments.length > 1) {
                        const newAccompaniments = accompaniments.filter((_, i) => i !== index);
                        setAccompaniments(newAccompaniments);
                        setTimeout(() => focusInput(index - 1, type), 0);
                    }
                    break;
                default:
                    break;
            }
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            handleArrowNavigation(event, index, type);
        }
    };

    const handleArrowNavigation = (event, index, type) => {
        if (event.key === 'ArrowUp' && index > 0) {
            setTimeout(() => focusInput(index - 1, type), 0);
        } else if (event.key === 'ArrowDown') {
            if (type === 'entrance' && index < entranceRefs.current.length - 1) {
                setTimeout(() => focusInput(index + 1, type), 0);
            } else if (type === 'main' && index < mainRefs.current.length - 1) {
                setTimeout(() => focusInput(index + 1, type), 0);
            } else if (type === 'accompaniment' && index < accompanimentRefs.current.length - 1) {
                setTimeout(() => focusInput(index + 1, type), 0);
            }
        }
    };

    const focusInput = (index, type) => {
        let input;
        if (index >= 0) {
            if (type === 'entrance') {
                input = entranceRefs.current[index];
            } else if (type === 'main') {
                input = mainRefs.current[index];
            } else if (type === 'accompaniment') {
                input = accompanimentRefs.current[index];
            }
            if (input) {
                input.focus();
                setTimeout(() => {
                    input.setSelectionRange(input.value.length, input.value.length);
                }, 0);
            }
        }
    };

    const handleInputChange = (event, index, type) => {
        const value = event.target.value;
        if (value.length <= 30) { 
            switch (type) {
                case 'entrance':
                    setEntrances((prev) => prev.map((item, i) => (i === index ? value : item)));
                    break;
                case 'main':
                    setMains((prev) => prev.map((item, i) => (i === index ? value : item)));
                    break;
                case 'accompaniment':
                    setAccompaniments((prev) => prev.map((item, i) => (i === index ? value : item)));
                    break;
                default:
                    break;
            }
        }
    };

    const handlePriceChange = (event) => {
        let value = event.target.value;
        value = value.replace(/[^0-9]/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2);
        }
        setPrice(value);
    };

    const showAlert = (message, type) => {
        setAlert({ message, type, isVisible: true });
        setTimeout(() => {
            setAlert({ message: '', type: '', isVisible: false });
        }, 5000);
    };

    const handleSubmit = async () => {
        const menuData = {
            entrance: JSON.stringify(entrances.filter(e => e.trim() !== '')),
            main: JSON.stringify(mains.filter(m => m.trim() !== '')),
            accompaniment: JSON.stringify(accompaniments.filter(a => a.trim() !== '')),
            price: parseFloat(price)
        };

        try {
            const response = await updateMenu(menuData);

            if (response.success) {
                showAlert('Menu mis à jour avec succès !', 'success');
            } else {
                showAlert('Erreur lors de la mise à jour du menu : ' + response.message, 'error');
            }
        } catch (error) {
            console.error('Erreur:', error);
            showAlert('Erreur lors de la mise à jour du menu.', 'error');
        }
    };

    const updateMenu = async (menuData) => {
        const response = await fetch('https://alexotique.ch/alexotique/src/Exotique/Backend/Menu.php', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(menuData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to update menu');
        }
    };

    return (
        <div className='background-menumanagement'>
            <div className='dialog-menumanagement'>
                <FontAwesomeIcon className='close' icon={faRightFromBracket} onClick={handleLogout} />
                <h1>Menu du jour</h1>
                <h5>
                    pour seulement 
                    <input 
                        type="text" 
                        value={price}
                        placeholder="00" 
                        onChange={handlePriceChange}
                    />
                    <span>.-</span>
                </h5>
                <div className='container-entrance'>
                    <h2>Nos entrées</h2>
                    {entrances.map((entrance, index) => (
                        <input
                            key={index}
                            ref={el => entranceRefs.current[index] = el}
                            type="text"
                            value={entrance}
                            maxLength="30"
                            placeholder="Vide" 
                            onChange={(e) => handleInputChange(e, index, 'entrance')}
                            onKeyDown={(e) => handleKeyDown(e, index, 'entrance')}
                        />
                    ))}
                </div>
                <div className='container-main'>
                    <h3>Nos plats principaux</h3>
                    {mains.map((main, index) => (
                        <input
                            key={index}
                            ref={el => mainRefs.current[index] = el}
                            type="text"
                            value={main}
                            maxLength="30"
                            placeholder="Vide" 
                            onChange={(e) => handleInputChange(e, index, 'main')}
                            onKeyDown={(e) => handleKeyDown(e, index, 'main')}
                        />
                    ))}
                </div>
                <div className='container-accompaniement'>
                    <h4>Nos accompagnements</h4>
                    {accompaniments.map((accompaniment, index) => (
                        <input
                            key={index}
                            ref={el => accompanimentRefs.current[index] = el}
                            type="text"
                            value={accompaniment}
                            maxLength="30"
                            placeholder="Vide" 
                            onChange={(e) => handleInputChange(e, index, 'accompaniment')}
                            onKeyDown={(e) => handleKeyDown(e, index, 'accompaniment')}
                        />
                    ))}
                </div>
                <div className='container-btn'>
                    <button onClick={handleSubmit}>Publier</button>
                </div>
                {alert.isVisible && <Alert message={alert.message} type={alert.type} onClose={() => setAlert({ message: '', type: '', isVisible: false })} />}
            </div>
        </div>
    );
}

export default MenuManagement;
