import React, { useState } from 'react';
import './Connection.css';
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';

function Connection() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const validateInput = (input) => {
        const regex = /[`~<>;:"'/[\]|{}()=+]/;
        return !regex.test(input);
    };

    const handleLogin = () => {
        if (!validateInput(username) || !validateInput(password)) {
            setError('Le champ contient des caractères invalides.');
            setShowAlert(true);
            return;
        }

        fetch('https://alexotique.ch/alexotique/src/Exotique/Backend/Connection.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                login();
                navigate('/panel'); 
            } else {
                setError(data.message);
                setShowAlert(true);
            }
        })
        .catch(error => {
            setError('Erreur de connexion.');
            setShowAlert(true);
        });
    };

    return (
        <div className='background-connection'>
            {showAlert && (
                <Alert
                    message={error}
                    type="error"
                    onClose={() => setShowAlert(false)}
                />
            )}
            <div className='container-connection'>
                <h1>Connexion</h1>
                <p>Veuillez remplir les champs ci-dessous.</p>
                <div className='input-box'>
                    <label htmlFor="username">Nom d'utilisateur</label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className='input-box'>
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className='container-btn'>
                    <button onClick={handleLogin}>Connexion</button>
                </div>
            </div>
        </div>
    );
}

export default Connection;
