import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
    return (
        <footer>
            <div className='container-top'>
                <div className='box'>
                    <h1>Nos coordonnées</h1>
                    <span>À l'Exotique</span>
                    <a 
                        href="https://www.google.com/maps?q=Rte+de+Cheseaux+3,+1054+Morrens" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ textDecoration: 'none', color: '#ffffff' }}
                    >
                        Rte de Cheseaux 3,<br />1054 Morrens
                    </a>
                </div>
                <div className='box'>
                    <h1>Nos horaires</h1>
                    <div className='container-hours'>
                        <span>Mar-Sam :</span>
                        <div className='hours'>
                            <span>18h00 - 21h00</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Dimanche :</span>
                        <div className='hours'>
                            <span>Fermé</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Lundi :</span>
                        <div className='hours'>
                            <span>Fermé</span>
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <h1>Contactez-nous</h1>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faPhone} />
                        <a href="tel:+41217321104" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>+41 21 732 11 04</span>
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faEnvelope} />
                        <a href="mailto:info@alexotique.ch" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>info@alexotique.ch</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='container-center'>
                © 2024 tout droit réservé à l'Exotique.
            </div>
            <div className='container-bottom'>
                Conçu par <a href="https://www.logixcube.ch/" style={{ textDecoration: 'none', color: '#ffffff' }} target="_blank" rel="noopener noreferrer">Logixcube.ch</a>
            </div>
        </footer>
    );
}

export default Footer;
