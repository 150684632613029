import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Menu.css';

function Menu({ closeMenu }) {
    const [menuData, setMenuData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            fetch('https://alexotique.ch/alexotique/src/Exotique/Backend/Menu.php?id=1')
                .then(response => response.json())
                .then(data => {
                    setMenuData(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération du menu:', error);
                    setIsLoading(false);
                });
        };
        
        fetchData(); 
        const intervalId = setInterval(fetchData, 2000);

        return () => clearInterval(intervalId); 
    }, []);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            closeMenu();
        }, 300);
    };

    const openOrderOnline = () => {
        window.open('https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=68e0b2de-f6d5-4626-a542-d9e381c34872', '_blank');
    };

    if (isLoading) {
        return <p>Chargement...</p>;
    }

    if (!menuData) {
        return <p>Erreur de chargement du menu.</p>;
    }

    const { entrance, main, accompaniment, price } = menuData;

    return (
        <div className='background-menu'>
            <div className={`dialog-menu ${isClosing ? 'closing' : ''}`}>
                <FontAwesomeIcon className='close' onClick={handleClose} icon={faXmark} />
                <h1>Menu du jour</h1>
                <h5>pour seulement <span>{price}.-</span></h5>

                {entrance && entrance.length > 0 && (
                    <div className='container-entrance'>
                        <h2>Nos entrées</h2>
                        {entrance.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                )}

                {main && main.length > 0 && (
                    <div className='container-main'>
                        <h3>Nos plats principaux</h3>
                        {main.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                )}

                {accompaniment && accompaniment.length > 0 && (
                    <div className='container-accompaniement'>
                        <h4>Nos accompagnements</h4>
                        {accompaniment.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                )}

                <div className='container-btn'>
                    <button onClick={openOrderOnline} >Commander en ligne</button>
                </div>
            </div>
        </div>
    );
}

export default Menu;
